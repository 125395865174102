import { LinkingOptions } from '@react-navigation/native';
import { ScreenNames } from '../screens/ScreenNames';

export const LinkingConfig: LinkingOptions<{}>['config'] = {
  screens: {
    [ScreenNames.userStack]: {
      path: 'utilizator',
      screens: {
        [ScreenNames.login]: 'conectare',
        [ScreenNames.signUp]: 'inregistrare',
        [ScreenNames.verificationCode]: 'verificare-cod',
        [ScreenNames.gdprCompliance]: 'conformitate-gdpr',
        [ScreenNames.changePassword]: 'schimbare-parola',
        [ScreenNames.emailRegisterConfirm]: 'email-trimis',
        [ScreenNames.forgotPassword]: 'parola-uitata',
        [ScreenNames.resetPassword]: 'resetare-parola',
        [ScreenNames.successRegistration]: 'validare-email',
        [ScreenNames.userMigration]: 'migrare',
      },
    },
    [ScreenNames.appStack]: {
      path: '',
      screens: {
        feed: {
          path: 'feed',
          screens: {
            [ScreenNames.feed]: '',
          },
        },
        products: {
          path: 'produse',
          screens: {
            [ScreenNames.products]: '',
            [ScreenNames.productCategories]: ':categorySlug',
            [ScreenNames.productDetails]: ':categorySlug/:productSlug',
            [ScreenNames.kentModeInfinite2]: '/kent-mode-infinite-possibilities',
            [ScreenNames.gloHyperProProduct]: '/glo-hyper-pro',
          },
        },
        campaigns: {
          path: 'campanii',
          screens: {
            [ScreenNames.campaignsList]: '',
          },
        },
        campaign: {
          path: 'campanie',
          screens: {
            [ScreenNames.campaignDetailsHub]: ':campaignSlug',
            [ScreenNames.campaignGalleryList]: ':campaignSlug/galerie',
            [ScreenNames.campaignUpload]: ':campaignSlug/incarca-media',
          },
        },
        prizes: {
          path: 'premii',
          screens: {
            [ScreenNames.prizeList]: '',
          },
        },
        wallet: {
          path: 'portofel',
          screens: {
            [ScreenNames.wallet]: '',
            [ScreenNames.howToEarnPoints]: 'cum-castigi-puncte',
            [ScreenNames.historyPoints]: 'istoric-puncte',
          },
        },
        favorites: {
          path: 'favorite',
          screens: { [ScreenNames.favorites]: '' },
        },
        collections: {
          path: 'colectii',
          screens: {
            [ScreenNames.collections]: '',
          },
        },
      },
    },
    articles: {
      path: 'articole',
      screens: {
        [ScreenNames.articleList]: ':brandSlug/:territorySlug',
        [ScreenNames.articleView]: ':brandSlug/produs/:articleSlug',
        [ScreenNames.articleView]: ':brandSlug/:territorySlug/:articleSlug',
        [ScreenNames.relatedArticlesProducts]: ':brandSlug/produse-similare/:articleSlug',
        [ScreenNames.relatedArticlesSimple]: ':brandSlug/:territorySlug/similare/:articleSlug',
        [ScreenNames.pallMallArticle]: 'pall-mall/pall-mall-istoria-de-125-ani',
      },
    },
    profile: {
      path: 'profil',
      screens: {
        [ScreenNames.profile]: '',
        [ScreenNames.personalDetails]: 'detalii-personale',
        [ScreenNames.devices]: 'dispozitive',
        [ScreenNames.inviteFriend]: 'invita-prieteni',
        [ScreenNames.help]: 'ajutor',
        [ScreenNames.changePassword]: 'schimba-parola',
        [ScreenNames.privacy]: 'confidentialitate',
        [ScreenNames.cookiesSettings]: 'setari-cookieuri',
        [ScreenNames.helpfulInformation]: 'informatii-utile',
        [ScreenNames.contactUs]: 'contacteaza-ne',
        [ScreenNames.faq]: 'faq',
      },
    },
    prize: {
      path: 'premiu',
      screens: {
        [ScreenNames.prizeView]: ':type/:prizeSlug',
        [ScreenNames.momentView]: 'moment/:prizeSlug',
        [ScreenNames.bundleView]: 'garantat/bundle/:prizeSlug',
        [ScreenNames.redeemCheckout]: '/:prizeSlug/checkout-revendicare/',
      },
    },
    survey: {
      path: 'sondaj',
      screens: {
        [ScreenNames.survey]: ':id',
        [ScreenNames.surveySuccess]: 'succes',
        [ScreenNames.tasteProfile]: '/preferintele-mele/produse-brandurile-mele',
      },
    },
    [ScreenNames.onBoarding]: 'inregistrare',
    [ScreenNames.stories]: 'stories/:author/:storyName',
    [ScreenNames.insertPackCode]: 'introducere-cod-pachet',
    [ScreenNames.quizzesList]: 'chestionare',
    [ScreenNames.pdfs]: 'pdfs',
    [ScreenNames.winPrizesPdfs]: 'castigatori-premii',
    [ScreenNames.viewer]: 'viewer',
    [ScreenNames.notification]: 'notificari',
    'age-verification': {
      path: 'verificare-varsta',
      screens: {
        [ScreenNames.startScreen]: 'ecran-pornire',
        [ScreenNames.faceCapture]: 'captura-fata',
        [ScreenNames.scanID]: 'scanare-id',
        [ScreenNames.verificationFailed]: 'verificare-esuata',
        [ScreenNames.verificationSucceed]: 'verificare-reusita',
        [ScreenNames.verificationMandatory]: 'verificare-obligatorie',
      },
    },
    [ScreenNames.allSearchResults]: 'rezultate-cautare',
    [ScreenNames.dunhillCustomerSurvey]: 'sondaj-dunhill-signature-limited-edition-feedback-2024',
  },
};
