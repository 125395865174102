import { CardProps } from '../src/components/BATCard';
import { BATListItemsSelectorItem } from '../src/components/common/BATListItemsSelector';
import { PagerModel } from './common';

export interface Prize {
  attributes: [
    {
      id: string;
      field_article_number: string;
      field_sku: string;
      field_option: string;
      field_stock: string;
      field_media_image: string;
    },
  ];
  body: string;
  can_redeem: {
    device_owner: boolean;
    loyalty_points: boolean;
    activity_points: boolean;
    pack_codes: boolean | string;
    brand_code: boolean;
    result: string;
    eligible_rules_days: string;
    eligible_rules_qty: string;
    is_age_verified: boolean;
    user_points: {
      activity_points: number;
      loyalty_points: number;
    };
  };
  chances: number;
  created: string;
  field_activity_points: string;
  field_brand: string;
  field_image: string;
  field_pack_points: string;
  field_prize_type: string;
  field_limited_prize_type?: string;
  field_has_digital_alternative?: number | string;
  field_digital_alternative_prize?: number | string;
  digital_alternative_url_alias: string;
  field_hide_from_list?: number | string;
  field_stock: string;
  field_subtype: string;
  field_video_link: string;
  title: string;
  field_nr_of_days: string;
  field_nr_of_prizes: string;
  field_attribute_type: string;
  field_sort_order: string;
  field_restrict_to_brand_points: string;
  field_restrict_to_glo_subscriber: number;
  field_restrict_to_pack_codes: string;
  field_restrict_to_ugc_photos: number;
  field_restrict_yoti_age_verified: number;
  field_minimum_pack_codes: string;
  field_required_codes_message: string;
  field_device_owners_only: string;
  nid: string;
  field_campaign_start_date: string;
  field_campaign_end_date: string;
  is_favorite: string;
  url_alias: string;
  bundle_groups?: {
    id: number;
    field_user_defined: number;
    field_delivery_method: BundleDeliveryMethods;
    field_title: string;
    field_input_type: BundleGroupSelectorTypes;
    field_quantity: number;
    field_batch_quantity: number;
    bundle_options: {
      title: string;
      thumbnail__target_id: string;
      field_stock: number;
      options: {
        id: string;
        field_option: string;
        field_media_image: string;
        field_stock: string;
        field_variation: string;
      }[];
    }[];
  }[];
  bundle_image?: string;
  field_delivery_method?: string;
  field_minimum_pack_codes_message?: string;
  field_pickup_description?: string;
  field_pickup_redeem_summary?: string;
  field_pickup_extra_activity?: number;
  field_pickup_extra_loyalty?: number;
  field_shipping_description?: string;
  field_shipping_redeem_summary?: string;
  field_shipping_extra_activity?: number;
  field_shipping_extra_loyalty?: number;
  pickup_image?: string;
  shipping_image?: string;
  final_activity_points?: number | string;
  final_loyalty_points?: number | string;
  prize_period: string;
}

export interface BundleVariation {
  selection_name: string;
  field_selector_type: BundleSuboptionVariationSelectorTypes;
  variation_id: string;
  variation: string;
  variation_icon: string;
}

export interface GetLimitedPrizes {
  pager: PagerModel;
  results: LimitedPrizes[];
}
export interface GetGuaranteedPrizes {
  pager: PagerModel;
  results: GuaranteedPrizes[];
}
export interface LimitedPrizes {
  campaign_priority: number;
  campaign_title: string;
  chances: number;
  created: string;
  field_activity_points: number;
  field_brand: string;
  field_brand_id?: number;
  field_image: string;
  field_limited_prize_type: string;
  field_pack_points: number;
  field_sort_order: string;
  field_video_link: string;
  is_favorite: number;
  nid: number;
  prize_period: string;
  title: string;
  url_alias: string;
}

export interface GuaranteedPrizes {
  created: string;
  field_activity_points: string;
  field_image: string;
  field_pack_points: string;
  field_prize_type: string;
  field_stock: string;
  title: string;
  nid: string;
  field_subtype?: string;
  field_brand_id?: string;
  field_brand?: string;
  field_video_link: string;
  url_alias: string;
  field_start_date?: string;
}

export interface LuckyMoments {
  nid: string;
  title: string;
  image: string;
  url_alias: string;
  start_date?: string;
}

export interface LuckyMoment extends LuckyMoments {
  description: string;
  start_date: string;
  end_date: string;
  field_brand: string;
}

export interface PrizeDetails extends PrizeDeliveryDetails {
  id: number;
  nrOfDays: number;
  nrOfPrizes: number;
  isOutOfStock: boolean;
}

export interface PrizeDeliveryDetails {
  image: string;
  title: string;
  description: string;
  redeemSummary: string;
  video?: string;
  requirements?: PrizeRequirements;
  deliveryMethod?: string;
}

export type PrizeRequirements = {
  minActivityPoints: number;
  userHasActivityPoints: boolean;
  discountedActivityPoints?: number;

  minLoyaltyPoints: number;
  userHasLoyaltyPoints: boolean;
  discountedLoyaltyPoints?: number;

  minBrandPoints: number;
  userBrandPoints: number | boolean;
  restrictToBrandPoints: boolean;
  restrictToPackPoints: boolean;
  brandPointsMessage?: string;

  userIsEligibleRulesQuantity: boolean;
  userIsEligibleRulesDays: boolean;
  canAccessHiddenOffer?: boolean;

  brand: string;
  //TODO: gp + lp
  userHasDevice?: boolean;
  startRedeemPeriod?: string;
  stopRedeemPeriod?: string;

  canRedeemResult: string;
  discountedLabel?: { label: string; icon: string };

  restrictYoti?: boolean;

  restrictToUgcPhotos?: boolean;
  approvedUgcPhotos?: any;
  userUgcPhotos?: any;
  userHasNecessaryPictures?: boolean;

  restrictToGloSubscriber?: boolean;
  userIsGloSubscriber?: boolean;
};

export enum BundleDeliveryMethods {
  Any = 'any',
  Shipping = 'shipping',
  Pickup = 'pickup',
}

enum BundleSuboptionVariationSelectorTypes {
  Radio = 'radio',
  IconRadio = 'icon-radio',
  Checkbox = 'checkbox',
}

export enum BundleGroupSelectorTypes {
  Radio = 'radio',
  MultipleSelect = 'multiple_select',
  Variation = 'variation',
}

export type BundleSuboptionUi = {
  id: string;
  title: string;
  imageUrl: string;
  variations: BundleVariation[];
  stock: number;
  selectedQuantity: number;
};

export type BundleOptionUi = {
  title: string;
  imageUrl: string;
  suboptions: BundleSuboptionUi[];
  stock: number;
  selected: boolean;
  listItemsSelectorsSelectedItems: BATListItemsSelectorItem[];
};

export type BundleGroupUi = {
  id: number;
  deliveryMethod: BundleDeliveryMethods;
  title: string;
  subtitle: string;
  imageUrl?: string;
  quantity: number;
  batchQuantity: number;
  selectorType: BundleGroupSelectorTypes;
  bundleOptions: BundleOptionUi[];
  completed: boolean;
  isOutOfStock: boolean;
  isGift: boolean;
};

export type PrizeUi = {
  prizeAsCardData: CardProps;
  prizeDetails: PrizeDetails;
  prizeShippingDeliveryDetails?: PrizeDeliveryDetails;
  prizePickupDeliveryDetails?: PrizeDeliveryDetails;
  bundleGroups?: BundleGroupUi[];
  needsToReCompleteUserSelection?: boolean;
};

export type PromotedPrizeType = 'Guaranteed prize' | 'Limited prize' | 'Lucky Moments Campaign';

export interface PromotedPrize {
  nid: string;
  type: PromotedPrizeType;
  title: string;
  created: string;
  field_brand: string;
  field_image: string;
  url_alias: string;
  brand_url_alias: string;
  territory_url_alias: string;
  activity_points?: string;
  loyalty_points?: string;
  chances?: number;
}

export type GetPromotedPrizesResponse = Record<string, PromotedPrize[]>;
